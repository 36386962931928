<template>
  <div class="desktop-Carousel">
    <p class="desktop-text">您的工作可能会用到以下软件</p>
    <div class="desktop-one" v-if="solveSoftwareBanner.length >= 7">
      <div class="swiper-button-prev" @click="bannerSwiperPrev"></div>
      <div>
        <swiper
          :modules="modules"
          :slides-per-view="7"
          :loop="true"
          :Navigation="swiperOption"
          @swiper="onSwiper"
        >
          <swiper-slide v-for="item in solveSoftwareBanner" :key="item.id">
            <RouterLink :to="`/profession/${item.id}`" class="image">
              <div class="goods-item">
                <!-- <div class="image"> -->
                <!-- <div class="image-picture"> -->
                <img :src="item.softwareLogo" alt="" />
                <!-- </div> -->
                <!-- </div> -->
              </div>
            </RouterLink>
          </swiper-slide>
        </swiper>
      </div>
      <div class="swiper-button-next" @click="bannerSwiperNext"></div>
      <!-- </div> -->
    </div>
    <div v-else class="desktop-one-1">
      <div class="goods-item-1">
        <div v-for="item in solveSoftwareBanner" :key="item.id">
          <RouterLink :to="`/profession/${item.id}`" class="image">
            <div class="goods-item">
              <img :src="item.softwareLogo" alt="" />
            </div>
          </RouterLink>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import "swiper/swiper-bundle.css";
import { EffectFade, Autoplay, Pagination, Navigation } from "swiper";
import { solveSoftware } from "@/api/solve/index.js";
import { useRoute } from "vue-router";
import { Software } from "@/api/Home/index.js";
const modules = [Autoplay, Navigation, Pagination, EffectFade];

const swiperOption = ref({});
const getData = () => {
  swiperOption.value = {
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  };
};

const status = ref(null);
const onSwiper = (swiper) => {
  status.value = swiper;
  // console.log(swiper);
};
const bannerSwiperPrev = () => {
  status.value.slidePrev();
};
const bannerSwiperNext = () => {
  status.value.slideNext();
};
let solveSoftwareBanner = ref([]);
const route = useRoute();
console.log(route);
let domainParent = route.params.id;
const getSolveSoftware = async () => {
  try {
    let data = await Software();
    solveSoftwareBanner.value = data.data[domainParent].softwareResponseVOList;
    console.log(solveSoftwareBanner.value, "软件");
  } catch (error) {
    console.log(error);
  }
};
onMounted(() => {
  getData();
  getSolveSoftware();
});
</script>

<style lang="less" scoped>
.desktop-Carousel {
  width: 1920px;
  height: 270px;
  margin: 0 auto;
  .desktop-text {
    width: 1440px;
    margin: 0 auto;
    text-align: center;
    font-size: 32px;
    font-weight: 400;
    color: #111111;
    margin-bottom: 40px;
    margin-top: 50px;
  }
  .desktop-one {
    width: 1440px;
    height: 520px;
    text-align: center;
    margin: auto;
    .goods-item {
      width: 180px;
      height: 180px;
      position: relative;
      overflow: hidden;
      // background: #f2f2f2;
      background-color: #fff;
      margin-right: 16px;
      margin-left: 1px;
      margin-bottom: 20px;
      cursor: pointer;
      .hoverShadow();
      img {
        width: 180px;
        height: 180px;
      }
    }
  }
  .desktop-one-1 {
    width: 1440px;
    height: 520px;
    text-align: center;
    margin: auto;
    .goods-item-1 {
      display: flex;
      justify-content: center;
      .goods-item {
        width: 180px;
        height: 180px;
        position: relative;
        overflow: hidden;
        // background: #f2f2f2;
        background-color: #fff;
        margin-right: 16px;
        margin-left: 1px;
        margin-bottom: 20px;
        cursor: pointer;
        .hoverShadow();
        img {
          width: 180px;
          height: 180px;
        }
      }
    }
  }
  .swiper-button-prev,
  .swiper-button-next {
    top: 826px;
    color: #111;
  }
  .swiper-button-next {
    right: 198px;
  }
  .swiper-button-prev {
    left: 198px;
  }
}
</style>
