<template>
  <AppLayout>
    <div class="solve">
      <div class="solve-content">
        <img :src="solvegoodsBanner" style="width: 100%;height: 600px;">
      </div>
      <!-- <div class="solve-content1" v-if="imgId === '2'"></div>
      <div class="solve-content2" v-if="imgId === '3'"></div>
      <div class="solve-content3" v-if="imgId === '4'"></div>
      <div class="solve-content4" v-if="imgId === '5'"></div> -->
      <!-- <div class="lx">
        <div class="home-b">
          <div class="home-b-text">
            <div class="home-left">
              <p>业务咨询</p>
              <p>随时联系我们的咨询专家</p>
              <p>
                <span class="ht" style="position: relative;top: 3px;"></span>
                <span>韩经理：13995648183</span>
              </p>
            </div>
            <div class="home-left-1">
              <p>商务邮箱</p>
              <p>我们的商务经理会第一时间联系您</p>
              <p>
                <img style="position: relative; top: -2px;right: 5px;" src="../../assets/images/Lx/z-1-4.png"/>
                <span>zuoyi@iningmei.com</span>
              </p>
            </div>
            <div class="home-two">
              <div class="home-content">
                <p></p>
                <p>在线联系我们，获得专业建议</p>
                <p @click="handleOpen">立即咨询</p>
              </div>
              <div class="home-right">
                <p></p>
                <p>提交产品需求，保持沟通</p>
                <p @click="workOrderSubmite">立即提交</p>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <el-dialog
        style="
          border-radius: 20px;
          width: 521px;
          height: 646px;
          position: relative;
        "
        :show-close="false"
        destroy-on-close="true"
        v-model="isVisible"
      >
        <workOrder
          @closeOpen="closeOpen"
          style="position: absolute; left: -63px; top: 30px"
        />
      </el-dialog>
      <SolvePs />
      <SolveBanner />
    </div>
    <SolveCarousel />
  </AppLayout>
  <div :class="!isShowLeft ? 'pageLeft1' : 'pageLeft'">
    <div v-if="!isShowLeft" class="pageLeft-jiangtou" @click="isShowthis">
      <img style="width: 24px; height: 28px" src="./img/z-0.png" alt="" />
    </div>
    <div class="pageLeft-1" v-if="isShowLeft">
      <div class="pageLeft-2">
        <img style="width: 20px; height: 20px" src="./img/z-2.png" />
        <div class="pageLeft-3">业务咨询</div>
        <div class="pageLeft-4">（韩经理：13995648183）</div>
        <img style="width: 24px; height: 20px" src="./img/z-4.png" />
        <div class="pageLeft-5">商务邮箱</div>
        <div class="pageLeft-6">（zuoyi@iningmei.com）</div>
        <img style="width: 23px; height: 20px;cursor: pointer;" src="./img/z-3.png" @click="handleOpen" />
        <div class="pageLeft-8" @click="handleOpen">在线咨询</div>
        <img
          style="width: 6px; height: 12px; margin-top: 5px; margin-left: 8px;cursor: pointer;"
          src="./img/z-6.png"
          @click="handleOpen"
        />
        <img
          style="width: 20px; height: 20px; margin-left: 127px;cursor: pointer;"
          src="./img/z-5.png"
          @click="workSubmite"
        />
        <div class="pageLeft-9" @click="workSubmite">提交需求</div>
        <img
          style="width: 6px; height: 12px; margin-top: 5px; margin-left: 8px;cursor: pointer;"
          src="./img/z-6.png"
          @click="workSubmite"
        />
      </div>
      <div class="pageLeft-10">
        <div class="pageLeft-10-1">随时联系我们的咨询专家为您答疑解惑</div>
        <div class="pageLeft-10-2">我们的商务经理收到邮件会第一时间联系您</div>
        <div class="pageLeft-10-3" @click="handleOpen">在线咨询，技术专家将为您即时解答</div>
        <div class="pageLeft-10-4" @click="workSubmite">
          提交产品需求，我们将更精准的为您提供服务
        </div>
        <img
          style="width: 16px; height: 16px"
          class="pageLeft-10-5"
          @click="closeLeft"
          src="./img/z-1.png"
        />
      </div>
    </div>
  </div>
  <HomeBackup />
</template>

<script setup>
import AppLayout from "@/components/AppLayout";
import HomeBackup from "@/views/home/components/HomeBackup";
import SolvePs from "./components/SolvePs.vue";
import SolveCarousel from "./components/SolveCarousel.vue";
import SolveBanner from "./components/SolveBanner.vue";
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import workOrder from "@/views/workOrder";
import emitter from "@/utils/eventBus";
import { useRouter } from "vue-router";
import { domainById } from "../../api/solve/index.js";
const route = useRoute();
let domainid = route.params.domainid;
let solvegoodsBanner = ref([]);
onMounted(() => {
  setTimeout(function () {
    isShowLeft.value = false;
  }, 2500);
  getSolveProduct();
});
const getSolveProduct = async () => {
  try {
    let goodsdata = await domainById(domainid);
    solvegoodsBanner.value = goodsdata.data.bannerPictures[0].img;
  } catch (error) {
    console.log(error);
  }
};
const router = useRouter();
const workSubmite = () => {
  router.push({
    path: "/Customize",
    query: {
      item: "customizeDataList",
    },
  });
};
const isShowLeft = ref(true);
const closeLeft = () => {
  isShowLeft.value = false;
};
const isShowthis = () => {
  isShowLeft.value = true;
};
const handleOpen = () => {
  emitter.emit("onlineClient");
};
const isVisible = ref(false);
const workOrderSubmite = () => {
  isVisible.value = true;
};
const closeOpen = () => {
  isVisible.value = false;
};
console.log(route.params.id === "1");
const imgId = ref("");
imgId.value = route.params.id;
</script>

<style lang="less" scoped>
.pageLeft1 {
  width: 100px;
  height: 140px;
  background: #ffffff;
  box-shadow: 0px 1px 30px 0px rgba(0, 0, 0, 0.1);
  opacity: 0.95;
  border-radius: 0px 10px 10px 0px;
  bottom: 50px;
  position: fixed;
  z-index: 100;
  .pageLeft-jiangtou {
    margin: 56px 0 0 40px;
    cursor: pointer;
  }
}
.pageLeft {
  width: 100px;
  height: 140px;
  box-shadow: 0px 1px 30px 0px rgba(0, 0, 0, 0.1);
  opacity: 0.95;
  border-radius: 0px 10px 10px 0px;
  bottom: 50px;
  position: fixed;
  z-index: 100;
  .pageLeft-jiangtou {
    margin: 56px 0 0 40px;
    cursor: pointer;
  }
  .pageLeft-1 {
    width: 1440px;
    height: 140px;
    background: #ffffff;
    box-shadow: 0px 1px 30px 0px rgba(0, 0, 0, 0.1);
    opacity: 0.95;
    border-radius: 0px 10px 10px 0px;
    .pageLeft-2 {
      display: flex;
      padding: 45px 0 0 78px;
      .pageLeft-3 {
        width: 90px;
        height: 19px;
        line-height: 19px;
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #111111;
        padding-left: 8px;
      }
      .pageLeft-4 {
        width: 240px;
        height: 17px;
        line-height: 17px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ce1200;
      }
      .pageLeft-5 {
        width: 90px;
        height: 20px;
        line-height: 20px;
        font-size: 20px;
        padding-left: 8px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #111111;
      }
      .pageLeft-6 {
        width: 229px;
        height: 17px;
        line-height: 17px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ce1200;
      }
      .pageLeft-8 {
        width: 90px;
        height: 19px;
        line-height: 19px;
        font-size: 20px;
        padding-left: 8px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #111111;
        cursor: pointer;
      }
      .pageLeft-9 {
        width: 90px;
        height: 20px;
        line-height: 18px;
        font-size: 20px;
        padding-left: 8px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #111111;
        cursor: pointer;
      }
    }
    .pageLeft-10 {
      padding: 15px 0 0 77px;
      display: flex;
      .pageLeft-10-1 {
        width: 238px;
        height: 15px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
      }
      .pageLeft-10-2 {
        width: 267px;
        height: 15px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        margin-left: 112px;
      }
      .pageLeft-10-3 {
        width: 225px;
        height: 15px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        margin-left: 74px;
        cursor: pointer;
      }
      .pageLeft-10-4 {
        width: 281px;
        height: 15px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        margin-left: 31px;
        cursor: pointer;
      }
      .pageLeft-10-5 {
        width: 281px;
        height: 15px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        margin-left: 51px;
        margin-top: -21px;
        cursor: pointer;
      }
    }
  }
}
.solve {
  width: 1920px;
  background-color: #fff;
  margin: 0 auto;
  .solve-content {
    width: 100%;
    height: 600px;
    /* background: url("../../assets/images/solve/nr.png");
    background-repeat: no-repeat;
    background-size: cover; */
  }
  .solve-content1 {
    width: 100%;
    height: 600px;
    background: url("../../assets/images/solve/gc.png");
    background-repeat: no-repeat;
    background-size: cover;
  }
  .solve-content2 {
    width: 100%;
    height: 600px;
    background: url("../../assets/images/solve/kx.png");
    background-repeat: no-repeat;
    background-size: cover;
  }
  .solve-content3 {
    width: 100%;
    height: 600px;
    background: url("../../assets/images/solve/mt.png");
    background-repeat: no-repeat;
    background-size: cover;
  }
  .solve-content4 {
    width: 100%;
    height: 600px;
    background: url("../../assets/images/solve/swbg-1.png");
    background-repeat: no-repeat;
    background-size: cover;
  }
  .lx {
    width: 1920px;
    background-color: #fff;
    padding-top: 30px;
    .home-b {
      width: 1440px;
      height: 200px;
      background-image: url("../../assets/images/solve/z-1\ \(13\).png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      margin: 0 auto;
      .home-b-text {
        display: flex;
        padding: 0 0 0 122px;
        .home-left {
          padding: 54px 0 61px 0;
          width: 326px;
          p:nth-child(1) {
            font-size: 20px;
            font-weight: 400;
            color: #111111;
            margin-bottom: 5px;
          }
          P:nth-child(2) {
            font-size: 18px;
            font-weight: 400;
            color: #666666;
            margin-bottom: 5px;
          }
          P:nth-child(3) {
            font-size: 24px;
            font-weight: 400;
            color: #ce1200;
            .ht {
              display: inline-block;
              width: 20px;
              height: 24px;
              background-image: url("../../assets/images/solve/z-1\ \(9\).png");
              background-repeat: no-repeat;
              background-position: center;
              background-size: cover;
              margin-right: 7px;
            }
          }
        }
        .home-left-1 {
          margin-top: 54px;
          width: 369px;
          p:nth-child(1) {
            font-size: 20px;
            font-weight: 400;
            color: #111111;
            margin-bottom: 5px;
          }
          P:nth-child(2) {
            font-size: 18px;
            font-weight: 400;
            color: #666666;
            margin-bottom: 5px;
          }
          P:nth-child(3) {
            font-size: 24px;
            font-weight: 400;
            margin-left: 3px;
            color: #ce1200;
            .ht {
              display: inline-block;
              /* width: 24px;
            height: 24px; */
              /* background-image: url("../../../assets/images/Lx/z-1-4.png"); */
              background-repeat: no-repeat;
              background-position: center;
              background-size: cover;
              margin-right: 7px;
            }
          }
        }
        .home-two {
          display: flex;
          .home-content {
            padding: 45px 48px 45px 0;
            p:nth-child(1) {
              width: 40px;
              height: 36px;
              background-image: url("../../assets/images/solve/td.png");
              background-repeat: no-repeat;
              background-position: center;
              background-size: cover;
              margin: auto;
            }
            p:nth-child(2) {
              font-size: 18px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #111111;
              margin: 14px 0 11px 0;
            }
            p:nth-child(3) {
              width: 120px;
              height: 30px;
              line-height: 30px;
              background: #ce1200;
              border-radius: 15px;
              color: #ffffff;
              margin: 0 auto;
              text-align: center;
              cursor: pointer;
            }
          }
          .home-right {
            padding: 41px 0 39px 0;
            p:nth-child(1) {
              margin-bottom: 11px;
              width: 43px;
              height: 43px;
              background-image: url("../../assets/images/solve/xz.png");
              background-repeat: no-repeat;
              background-position: center;
              background-size: cover;
              margin: auto;
            }
            P:nth-child(2) {
              font-size: 18px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #111111;
              margin: 11px 0 11px 0;
            }
            p:nth-child(3) {
              width: 120px;
              height: 30px;
              line-height: 30px;
              background: #ce1200;
              border-radius: 15px;
              color: #ffffff;
              margin: 0 auto;
              text-align: center;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
</style>
