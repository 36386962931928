<template>
  <div class="user-container">
    <div class="user-nav">
      <div class="user-content">
        <p class="text">
          专注于为企业提供高性能的工作站。针对企业办公场景，帮助应用于内容创作、游戏设计、工程、科学研究和人工智能。致力于解决企业生产硬件需求，帮助企业加速生产、提高能效。
        </p>
        <p class="title">各类场景都能轻松应对</p>
      </div>
      <div class="or-container">
        <div
          class="box"
          v-for="(item, i) in picList"
          :key="item.id + i"
          @mouseenter="enter(i)"
          :class="eleIndex === i ? 'eleActive' : ''"
        >
          <div v-if="eleIndex == i">
            <div class="box-imgs">
              <img :src="item.bg" alt="" /><span
                @click="handleOpen"
                class="idCode"
                >{{ item.title }}</span
              >
            </div>
          </div>
          <div v-else>
            <img :src="item.img" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import emitter from "@/utils/eventBus";
const handleOpen = () => {
  emitter.emit("onlineClient");
};
const eleIndex = ref(0);
const picList = ref([
  {
    id: 1,
    text: "建模渲染",
    bg: require("../../../assets/images/home/accordion-1.png"),
    img: require("../../../assets/images/home/z-360.png"),
    title: "立即咨询",
  },
  {
    id: 2,
    text: "专业办公",
    bg: require("../../../assets/images/home/accordion-5.png"),
    img: require("../../../assets/images/home/z-361.png"),
    title: "立即咨询",
  },
  {
    id: 3,
    text: "内容创作",
    bg: require("../../../assets/images/home/accordion-4.png"),
    img: require("../../../assets/images/home/z-362.png"),
    title: "立即咨询",
  },
  {
    id: 4,
    text: "远程会议",
    bg: require("../../../assets/images/home/accordion-3.png"),
    img: require("../../../assets/images/home/z-363.png"),
    title: "立即咨询",
  },
  {
    id: 5,
    text: "IT信息开发",
    bg: require("../../../assets/images/home/accordion-2.png"),
    img: require("../../../assets/images/home/z-364.png"),
    title: "立即咨询",
  },
]);
const enter = (i) => {
  setTimeout(() => {
    eleIndex.value = i;
  }, 400);
};
</script>

<style lang="less" scoped>
.user-container {
  width: 1920px;
  margin: 0 auto;
  // margin: 60px 0;
  background-color: #ffffff;
  .user-text {
    width: 1680px;
    margin: 0 auto;
    text-align: center;
    font-size: 32px;
    font-weight: 400;
    color: #111111;
    margin-bottom: 40px;
  }
  .user-nav {
    width: 1680px;
    height: 650px;
    // background-color: #fff;
    margin: 0 auto;
    .user-content {
      padding-left: 120px;
      padding-right: 120px;
      .text {
        font-size: 24px;
        font-weight: 400;
        color: #666666;
        margin-bottom: 32px;
      }
      .title {
        text-align: center;
        font-size: 28px;
        font-weight: bold;
        color: #333333;
        margin-bottom: 40px;
      }
    }
    .or-container {
      height: 430px;
      width: 1680px;
      display: flex;
      justify-content: end;
      padding: 0 119px;
      .box {
        width: 200px;
        height: 430px;
        transition: all 0.5s ease-out;
        margin: 0 11px;
        overflow: hidden;
        .box-imgs {
          width: 554px;
          height: 430px;
          img {
            width: 554px;
            height: 430px;
          }
        }
        img {
          width: 200px;
          height: 430px;
          -o-object-fit: cover;
          object-fit: cover;
          transition: all 0.5s ease-out;
        }
        .idName {
          display: block;
          text-align: center;
          font-size: 20px;
          font-weight: 400;
          color: #ffffff;
          position: relative;
          bottom: 400px;
          right: 25px;
        }
        .idCode {
          display: block;
          width: 200px;
          height: 40px;
          line-height: 40px;
          background: #ce1200;
          text-align: center;
          font-size: 20px;
          font-weight: 400;
          color: #ffffff;
          position: relative;
          bottom: 85px;
          left: 33px;
          font-size: 14px;
          cursor: pointer;
        }
      }
      .eleActive {
        width: 554px;
        transition: all 0.5s ease-out;
      }

      .eleActive > img {
        width: 554px;
        height: 430px;
      }
    }
  }
}
</style>
