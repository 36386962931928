<template>
  <div class="desktop-Carousel">
    <p class="desktop-text">根据您的需求，为您推荐以下硬件帮助您提高生产力</p>
    <div class="desktop-content">
      <ui v-for="(item, index) in solvegoodsBanner.productInfoPOS" :key="item.id + index">
        <!--  @click="$router.push(`/detailsGoods/${item.id}`)" -->
        <!-- $router.push(`/detailsGoods/${item.id}/${item.configType}`) -->
        <div class="desktop-one">
          <li class="desktop-one" @click="handsAll(item)">
            <p class="desktop-content-all-img">
              <img :src="item.picture" alt="" />
            </p>
            <p class="desktop-content-all-t" :title="item.title">
              {{ item.productName }}
            </p>
            <p
              class="desktop-content-all-c"
              style="margin-top: -6px"
              :title="item.text"
            >
              {{ item.described }}
            </p>
            <!-- <p class="desktop-btn">{{ item.configType }}</p> -->
          </li>
        </div>
      </ui>
    </div>
    <p class="user-text">专注于服务企业级用户，打造一流硬件解决方案</p>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";

import "swiper/swiper-bundle.css";

import { solveProduct,domainById } from "../../../api/solve/index.js";
import { useRoute, useRouter } from "vue-router";

let solveProductBanner = ref([]);
let solvegoodsBanner = ref([]);
let route = useRoute();
let domainParent = route.params.id;
let domainid = route.params.domainid;
const getSolveProduct = async () => {
  try {
    let data = await solveProduct(domainParent);
    let goodsdata = await domainById(domainid);
    solveProductBanner.value = data.data;
    solvegoodsBanner.value = goodsdata.data;
    console.log(solveProductBanner.value, "硬件");
  } catch (error) {
    console.log(error);
  }
};
const router = useRouter();

const handsAll = (item) => {
  if(item.machineType == 3 || item.machineType == 5 || item.machineType == 6){
    //router.push("/detailsGoodsNew/"+ item.id + "/1");
	  router.push({
		  name: "detailsGoodsNew",
		  query: {
			  id: item.id,
			  houseNo: 1
		  }
	  });
  }else{
    //router.push("/detailsGoods/"+ item.id + "/1");
	  router.push({
		  name: "detailsGoods",
		  query: {
			  id: item.id,
			  houseNo: 1
		  }
	  });
  }
  // const params = {
  //   id: item.id,
  //   houseNo: item.configType,
  // };
  // router.push({
  //   name: "detailsGoods",
  //   params,
  // });
};
onMounted(() => {
  getSolveProduct();
});
</script>

<style lang="less" scoped>
.desktop-Carousel {
  width: 1920px;
  // height: 520px;
  margin: 0 auto;
  .desktop-text {
    width: 1440px;
    padding-top: 25px;
    margin: 0 auto;
    text-align: center;
    font-size: 32px;
    font-weight: 400;
    color: #111111;
    margin-bottom: 14px;
    margin-top: 30px;
  }
  .desktop-content {
    width: 1440px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
    .desktop-one {
      width: 336px;
      height: 404px;
      background: #f2f2f2;
      margin-right: 23px;
      margin-bottom: 23px;
      cursor: pointer;
      .hoverShadow ();
      .desktop-content-all-img {
        width: 250px;
        height: 250px;
        margin-left: 44px;
        cursor: pointer;
        img {
          width: 250px;
          height: 260px;
          margin-top: 38px;
        }
      }
      .desktop-content-all-t {
        width: 210px;
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        margin: 0 auto;
        margin-top: 50px;
        margin-bottom: 13px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .desktop-content-all-c {
        width: 258px;
        height: 38px;
        text-align: center;
        font-size: 13px;
        font-weight: 400;
        color: #666666;
        line-height: 18px;
        margin: 0 auto;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        margin-bottom: 15px;
      }
      .desktop-btn {
        width: 220px;
        height: 36px;
        line-height: 36px;
        border: 1px solid #ce1200;
        border-radius: 5px;
        color: #ce1200;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        margin: 0 auto;
        text-align: center;
      }
    }
  }
  .user-text {
    width: 1680px;
    margin: 0 auto;
    text-align: center;
    font-size: 32px;
    font-weight: 400;
    color: #111111;
    padding-bottom: 20px;
    margin-top: 60px;
  }
}
</style>
